import * as React from "react"
import Seo from "../components/atoms/Seo/Seo"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import {css} from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image"
import BuenaSenalForm from "../components/organisms/Form/BuenaSenalForm"
import CountdownTimer from "react-component-countdown-timer";
import { Link } from "gatsby"

export default function BuenaSenal () {

  const sectionAvecesCss = css`
    text-align: center;
    font-size: calc(100vw * (calc(56 / var(--width_base))));
    line-height: calc(100vw * (calc(56 / var(--width_base))));
    font-family: "Gotham Book", sans-serif;
    padding-top: calc(100vw * (calc(170 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(130 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(56 / 1690)));
      line-height: calc(100vw * (calc(56 / 1690)));
      padding-top: calc(100vw * (calc(170 / 1690)));
      padding-bottom: calc(100vw * (calc(130 / 1690)));
    }
    
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(60 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(60 / var(--width_base))));
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(22 / var(--width_base))));
      max-width: calc(100vw * (calc(300 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
    }
    & br{
      @media (max-width: 767px) {
        display: none;          
      }
    }
    
  `;

  const sectionAcaCss = css`
    background-color: black;
    padding-top: calc(100vw * (calc(125 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(100 / var(--width_base))));
    font-size: calc(100vw * (calc(56 / var(--width_base))));
    line-height: calc(100vw * (calc(56 / var(--width_base))));
    color: white;
    text-align: center;
    font-family: "Gotham Medium", sans-serif;

    @media (max-width: 1440px) and (min-width: 1025px) {
      padding-top: calc(100vw * (calc(125 / 1690)));
      padding-bottom: calc(100vw * (calc(100 / 1690)));
      font-size: calc(100vw * (calc(56 / 1690)));
      line-height: calc(100vw * (calc(56 / 1690)));
    }
    
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(25 / var(--width_base))));
      font-size: calc(100vw * (calc(16 / var(--width_base))));          
    }
  `;

  const sectionCadaCss = css`
    padding-top: calc(100vw * (calc(140 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      padding-top: calc(100vw * (calc(140 / 1690)));
    }
    
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(60 / var(--width_base))));          
    }
  `;
  
  const sectionCadaTextoCss = css`
    font-family: "Gotham Book",sans-serif;
    font-size: calc(100vw * (calc(56 / var(--width_base))));
    line-height: calc(100vw * (calc(70 / var(--width_base))));
    text-align: center;
    margin-bottom: calc(100vw * (calc(156 / var(--width_base))));
    & b{
      font-family: "Gotham Bold",sans-serif;
    }

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(56 / 1690)));
      line-height: calc(100vw * (calc(70 / 1690)));
      margin-bottom: calc(100vw * (calc(156 / 1690)));
    }
    
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(16 / var(--width_base))));          
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    }
  `;
  
  const cardsProyectosCss = css`
    max-width: calc(100vw * (calc(1700 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    padding-bottom: calc(100vw * (calc(91 / var(--width_base))));
    overflow: hidden;

    @media (max-width: 1440px) and (min-width: 1025px) {
      max-width: calc(100vw * (calc(1700 / 1690)));
      padding-bottom: calc(100vw * (calc(91 / 1690)));
    }
    
    @media (max-width: 767px) {
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));       
    }
  `;
  
  const cardProyectoCss = css`
    line-height: 0;
    
    &:not(:last-of-type){
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
      
      @media (max-width: 1440px) and (min-width: 1025px) {
        margin-bottom: calc(100vw * (calc(30 / 1690)));
      });
      
    }
  `;

  const cardDistritoCss = css`
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    text-align: center;
    padding-top: calc(100vw * (calc(10 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(2 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(25 / 1690)));
      line-height: calc(100vw * (calc(25 / 1690)));
      padding-top: calc(100vw * (calc(10 / 1690)));
      padding-bottom: calc(100vw * (calc(10 / 1690)));
      margin-bottom: calc(100vw * (calc(2 / 1690)));
    });
    
  `;
  
  const cardImageCss = css`
    
  `;
  
  const cardDescriptionCss = css`
    background-color: #F3F1F1;
    text-align: center;
    padding: 
      calc(100vw * (calc(40 / var(--width_base))))
      calc(100vw * (calc(20 / var(--width_base))));
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1440px) and (min-width: 1025px) {
      padding:
        calc(100vw * (calc(40 / 1690)))
          calc(100vw * (calc(20 / 1690)));
    });
    
  `;
  
  const bannerCss = css`
    width: 100% !important;
    
  &.only-mov{
    @media (min-width: 768px) {
      display: none!important;
    }
  }
  &.only-desk{
    @media (max-width: 767px) {
      display: none!important;
    }
  }
    @media (max-width: 767px) {
      height: auto;          
    }
  `;

  const cardTitleCss = css`
    font-size: calc(100vw * (calc(30 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    font-family: "Gotham Bold",sans-serif;
    margin-bottom: calc(100vw * (calc(27 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(30 / 1690)));
      line-height: calc(100vw * (calc(30 / 1690)));
      margin-bottom: calc(100vw * (calc(27 / 1690)));
    });
  `;


  const cardText1Css = css`
    font-size: calc(100vw * (calc(19 / var(--width_base))));
    line-height: calc(100vw * (calc(19 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(27 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(19 / 1690)));
      line-height: calc(100vw * (calc(19 / 1690)));
      margin-bottom: calc(100vw * (calc(27 / 1690)));  
    });
  `;

  const cardText2Css = css`
    font-size: calc(100vw * (calc(17 / var(--width_base))));
    line-height: calc(100vw * (calc(17 / var(--width_base))));
    font-family: "Gotham Bold",sans-serif;
    color: #FF7280;
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    
    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(17 / 1690)));
      line-height: calc(100vw * (calc(17 / 1690)));
      margin-bottom: calc(100vw * (calc(10 / 1690)));
    });
  `;
  
  const cardSvgCss = css`
    width: calc(100vw * (calc(21 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 1440px) and (min-width: 1025px) {
      width: calc(100vw * (calc(21 / 1690)));
      margin-bottom: calc(100vw * (calc(20 / 1690)));
    });
  `;
  
  const cardBtnCss = css`
    display: flex !important;
    align-items: center;
    background-color: #ff7280;
    color: white;
    font-size: calc(100vw * (calc(17 / var(--width_base))));
    font-family: "Gotham Bold",sans-serif;
    min-height: calc(100vw * (calc(45 / var(--width_base))));
    padding: 
      calc(100vw * (calc(15 / var(--width_base))))
      calc(100vw * (calc(80 / var(--width_base))))
      calc(100vw * (calc(11 / var(--width_base))))
      calc(100vw * (calc(80 / var(--width_base))));
    border-radius: calc(100vw * (calc(20 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(17 / 1690)));
      min-height: calc(100vw * (calc(45 / 1690)));
      padding:
              calc(100vw * (calc(15 / 1690)))
              calc(100vw * (calc(80 / 1690)))
              calc(100vw * (calc(11 / 1690)))
              calc(100vw * (calc(80 / 1690)));
      border-radius: calc(100vw * (calc(20 / 1690)));
    });
    
  `;

  const sectionDescuentoCss = css`
    max-width: calc(100vw * (calc(1700 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1440px) and (min-width: 1025px) {
      max-width: calc(100vw * (calc(1700 / 1690)));
    });
    
    @media (max-width: 767px) {
      padding-left: 15px;          
      padding-right: 15px;          
    }
  `;

  const fondoInterferenciaCss = css`
    position: relative;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: calc(100vw * (calc(20 / var(--width_base))));
    border-top-right-radius: calc(100vw * (calc(20 / var(--width_base))));
    height: calc(100vw * (calc(500 / var(--width_base))));
    
    @media (max-width: 1440px) and (min-width: 1025px) {
      border-top-left-radius: calc(100vw * (calc(20 / 1690)));
      border-top-right-radius: calc(100vw * (calc(20 / 1690)));
      height: calc(100vw * (calc(500 / 1690)));
    });
    
    &::after{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      opacity: .7;
    }
    
    @media (max-width: 767px) {
      height: calc(100vw * (calc(250 / var(--width_base))));
    }
    
  `;

  const bannerDescuentoCss = css`
    position: relative;
    @media (max-width: 767px) {
      height: calc(100vw * (calc(250 / var(--width_base))));
    }
  `;
  

  const bannerDescuentoTextoCss = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: calc(100vw * (calc(29 / var(--width_base))));
    line-height: calc(100vw * (calc(29 / var(--width_base))));
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Gotham Book",sans-serif;

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(29 / 1690)));
      line-height: calc(100vw * (calc(29 / 1690)));
    });
    
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
    }
    
  `;

  const formularioCss = css`
    background-color: #1D1D1B;
    color: white;
    padding-top: calc(100vw * (calc(53 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(53 / var(--width_base))));
    border-bottom-left-radius: calc(100vw * (calc(20 / var(--width_base))));
    border-bottom-right-radius: calc(100vw * (calc(20 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      padding-top: calc(100vw * (calc(53 / 1690)));
      padding-bottom: calc(100vw * (calc(53 / 1690)));
      border-bottom-left-radius: calc(100vw * (calc(20 / 1690)));
      border-bottom-right-radius: calc(100vw * (calc(20 / 1690)));
    });
    
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const contentDescuentoCss = css`
    max-width: calc(100vw * (calc(935 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
    @media (max-width: 1440px) and (min-width: 1025px) {
      max-width: calc(100vw * (calc(935 / 1690)));
    });
  `;
  
  const formularioTitleCss = css`
    font-family: "Gotham Book",sans-serif;
    font-size: calc(100vw * (calc(17 / var(--width_base))));
    line-height: calc(100vw * (calc(17 / var(--width_base))));
    //font-size: calc(100vw * (calc(29 / var(--width_base))));
    //line-height: calc(100vw * (calc(29 / var(--width_base))));
    color: #ECEAE0;
    //margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(29 / var(--width_base))));
    
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const contadorCss = css`
    margin-top: calc(100vw * (calc(52 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));

    @media (max-width: 1440px) and (min-width: 1025px) {
      margin-top: calc(100vw * (calc(52 / 1690)));
      margin-bottom: calc(100vw * (calc(40 / 1690)));  
    });
    
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(30 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));          
      padding-right: calc(100vw * (calc(20 / var(--width_base))));          
    }
    
    & .label{
      font-size: calc(100vw * (calc(39 / var(--width_base)))) !important;
      line-height: calc(100vw * (calc(39 / var(--width_base)))) !important;
      font-family: "Gotham Book", sans-serif !important;
      margin-top: calc(100vw * (calc(30 / var(--width_base))));

      @media (max-width: 1440px) and (min-width: 1025px) {
        font-size: calc(100vw * (calc(39 / 1690))) !important;
        line-height: calc(100vw * (calc(39 / 1690))) !important;
        margin-top: calc(100vw * (calc(30 / 1690)));
      });
      
      @media (max-width: 767px) {
        margin-top: calc(100vw * (calc(10 / var(--width_base))));
        font-size: calc(100vw * (calc(16 / var(--width_base)))) !important;
        line-height: calc(100vw * (calc(16 / var(--width_base)))) !important;
      }
      
    }
    & .countBox{
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    
    & .count{
      text-align: center;
      background-color: transparent !important;
      color: white !important;
      font-size: calc(100vw * (calc(81 / var(--width_base)))) !important;;
      line-height: calc(100vw * (calc(81 / var(--width_base)))) !important;
      font-family: "Vcr", sans-serif !important;

      @media (max-width: 1440px) and (min-width: 1025px) {
        font-size: calc(100vw * (calc(81 / 1690))) !important;;
        line-height: calc(100vw * (calc(81 / 1690))) !important;
      });
      
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(30 / var(--width_base)))) !important;;
        line-height: calc(100vw * (calc(30 / var(--width_base)))) !important;          
      }
      
    }
    & .countBoxItem{
      display: flex;
      flex-direction: column-reverse;
    }
  `;
  
  
  const descuentoSmallCss = css`
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    line-height: calc(100vw * (calc(12 / var(--width_base))));
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    text-align: center;
    color: white;

    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(12 / 1690)));
      line-height: calc(100vw * (calc(12 / 1690)));
      margin-top: calc(100vw * (calc(20 / 1690)));
    });
    
  `;
  
  const vigenteCss = css`
    padding-top: calc(100vw * (calc(63 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(63 / var(--width_base))));
    font-size: calc(100vw * (calc(29 / var(--width_base))));
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    text-align: center;
    font-family: "Gotham Book",sans-serif;

    @media (max-width: 1440px) and (min-width: 1025px) {
      padding-top: calc(100vw * (calc(63 / 1690)));
      padding-bottom: calc(100vw * (calc(63 / 1690)));
      font-size: calc(100vw * (calc(29 / 1690)));
      line-height: calc(100vw * (calc(40 / 1690)));
    });
    
    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(24 / var(--width_base))));
    }
  `;
  
  const interferenciaCss = css`
    position: absolute;
    top: -50px;
    left: -50px;
    width: calc(100% + 50px);
    height: calc(100% + 50px);
    background: repeating-linear-gradient(#111, #111 50%, #fff 50%,#fff);
    background-size: calc(100vw * (calc(5 / var(--width_base)))) calc(100vw * (calc(5 / var(--width_base))));
    filter: url(#noise);
  `;
  
  const interferenciaSvgCss = css`
    width: 0;
    height: 0;
  `;
  
  
  let fechaFin = new Date('February 10, 2022 23:59:00');
  let fechaActual = new Date();
  let tiempoTranscurrido = 0;

  if(fechaFin.getTime() > fechaActual.getTime()){
    tiempoTranscurrido = fechaFin.getTime() - fechaActual.getTime();
  }

  return(
    <LayoutGeneral>
      <Seo title="Buena Señal" />
      <h1 className="d-none">Buena Señal</h1>
      <section>
        <StaticImage
          className="only-desk"
          css={bannerCss}
          src="../images/buena-senal/fondo-buena-senal.jpg"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Icono phone"
        />
        <StaticImage
          className="only-mov"
          css={bannerCss}
          src="../images/buena-senal/fondo-movil-buena-senal.jpg"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Icono phone"
        />
      </section>
      <section css={sectionAvecesCss}>
        <p>A veces solo necesitas una buena señal para <br/>comprarte el depa que estabas buscando.</p>
      </section>
      <section css={sectionAcaCss}>
        <p>ACÁ TE DAMOS MÁS DE UNA...</p>
      </section>
      <section css={sectionCadaCss}>
        <p css={sectionCadaTextoCss}>...cada buena señal viene con un <br/><b>buen descuento.</b></p>
        <div css={cardsProyectosCss}>
          <div css={cardProyectoCss} className="grid-25">
            <div css={css`background-color: #EEBEBA;${cardDistritoCss};`}>San Isidro</div>
            <StaticImage
              css={cardImageCss}
              src="../images/buena-senal/ugarte-y-moscoso-330.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
            />
            <StaticImage
              css={css`width: 100%;`}
              src="../images/buena-senal/linea1.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
            <div css={cardDescriptionCss}>
              <p css={cardTitleCss}>Ugarte y <br/>Moscoso 330</p>
              <p css={cardText1Css}>Depas de 2 y 3 dormitorios</p>
              <p css={cardText2Css}>CONTÁCTATE CON NUESTRO ASESOR</p>
              <svg viewBox="0 0 21.5 10.99" css={cardSvgCss}>
                <polygon fill="#ff7280" points="10.75 10.99 0 0 21.5 0 10.75 10.99" />
              </svg>
              <a css={cardBtnCss} type="button" href="https://wa.me/51993123000?text=Hola,%20quisiera%20conocer%20las%20Buenas%20Señales%20de%20Ugarte%20y%20Moscoso%20330.%20Mi%20nombre%20es:" target="_blank" rel="noreferrer noopener" >CLICK AQUÍ</a>
            </div>
            <StaticImage
              css={css`width: 100%`}
              src="../images/buena-senal/linea2.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
          </div>
          <div css={cardProyectoCss} className="grid-25">
            <div css={css`background-color: #A1BFCB;${cardDistritoCss};`}>San Isidro</div>
            <StaticImage
              css={cardImageCss}
              src="../images/buena-senal/machaypuito-160.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
            />
            <StaticImage
              css={css`width: 100%;`}
              src="../images/buena-senal/linea1.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
            <div css={cardDescriptionCss}>
              <p css={cardTitleCss}>Machaypuito <br/>160</p>
              <p css={cardText1Css}>Depas de 2 y 3 dormitorios</p>
              <p css={cardText2Css}>CONTÁCTATE CON NUESTRO ASESOR</p>
              <svg viewBox="0 0 21.5 10.99" css={cardSvgCss}>
                <polygon fill="#ff7280" points="10.75 10.99 0 0 21.5 0 10.75 10.99" />
              </svg>
              <a css={cardBtnCss} type="button" href="https://wa.me/51989018097?text=Hola,%20quisiera%20conocer%20las%20Buenas%20Señales%20de%20Machaypuito%20160.%20Mi%20nombre%20es:" target="_blank" rel="noreferrer noopener">CLICK AQUÍ</a>
            </div>
            <StaticImage
              css={css`width: 100%`}
              src="../images/buena-senal/linea2.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
          </div>
          <div css={cardProyectoCss} className="grid-25">
            <div css={css`background-color: #CCBEAC;${cardDistritoCss};`}>San Isidro</div>
            <StaticImage
              css={cardImageCss}
              src="../images/buena-senal/ugarte-y-moscoso-370.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
            />
            <StaticImage
              css={css`width: 100%;`}
              src="../images/buena-senal/linea1.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
            <div css={cardDescriptionCss}>
              <p css={cardTitleCss}>Ugarte y <br/>Moscoso 370</p>
              <p css={cardText1Css}>Depas de 2 y 3 dormitorios</p>
              <p css={cardText2Css}>CONTÁCTATE CON NUESTRO ASESOR</p>
              <svg viewBox="0 0 21.5 10.99" css={cardSvgCss}>
                <polygon fill="#ff7280" points="10.75 10.99 0 0 21.5 0 10.75 10.99" />
              </svg>
              <a css={cardBtnCss} type="button" href="https://wa.me/51993123000?text=Hola,%20quisiera%20conocer%20las%20Buenas%20Señales%20de%20Ugarte%20y%20Moscoso%20370.%20Mi%20nombre%20es:" target="_blank" rel="noreferrer noopener">CLICK AQUÍ</a>
            </div>
            <StaticImage
              css={css`width: 100%`}
              src="../images/buena-senal/linea2.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
          </div>
          <div css={cardProyectoCss} className="grid-25">
            <div css={css`background-color: #95CDC8;${cardDistritoCss};`}>Miraflores</div>
            <StaticImage
              css={cardImageCss}
              src="../images/buena-senal/toribio-polo-322.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
            />
            <StaticImage
              css={css`width: 100%;`}
              src="../images/buena-senal/linea1.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
            <div css={cardDescriptionCss}>
              <p css={cardTitleCss}>Toribio <br/>Polo 322</p>
              <p css={cardText1Css}>Depas de 2 dormitorios</p>
              <p css={cardText2Css}>CONTÁCTATE CON NUESTRO ASESOR</p>
              <svg viewBox="0 0 21.5 10.99" css={cardSvgCss}>
                <polygon fill="#ff7280" points="10.75 10.99 0 0 21.5 0 10.75 10.99" />
              </svg>
              <a css={cardBtnCss} type="button" href="https://wa.me/51989018097?text=Hola,%20quisiera%20conocer%20las%20Buenas%20Señales%20de%20Toribio%20Polo%20322.%20Mi%20nombre%20es:" target="_blank" rel="noreferrer noopener">CLICK AQUÍ</a>
            </div>
            <StaticImage
              css={css`width: 100%`}
              src="../images/buena-senal/linea2.jpg"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Icono phone"
              objectFit="contain"
            />
          </div>
        </div>
      </section>
      <section css={sectionDescuentoCss}>
        <div css={bannerDescuentoCss}>
            <div css={fondoInterferenciaCss}>
              <div css={interferenciaCss}/>
              <svg css={interferenciaSvgCss}>
                <filter id="noise">
                  <feTurbulence id="turbulence">
                    <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8;0.9 0.9;"
                             repeatCount="indefinite"/>
                  </feTurbulence>
                  <feDisplacementMap in="SourceGraphic" scale="60"/>
                </filter>
              </svg>
            </div>
            <div css={bannerDescuentoTextoCss}>
              <div css={contentDescuentoCss}>
                <div className="text-center">Descuento por tiempo limitado</div>
                <div css={contadorCss}>
                  <CountdownTimer count={tiempoTranscurrido/1000} border showTitle noPoints dayTitle="Días" hourTitle="Horas" minuteTitle="Minutos" secondTitle="Segundos" />
                </div>
                <div className="text-center">o hasta agotar stock*</div>
                <div className="text-center only-mov" css={descuentoSmallCss}>(*)Ver términos y condicones <Link to="/terminosycondiciones/" css={css`color: #00FFFF;`}>aquí</Link></div>
              </div>
            </div>
        </div>
        <div css={formularioCss}>
          <div css={contentDescuentoCss}>
            <div css={formularioTitleCss}>Déjanos tus datos y nos pondremos en contacto contigo.</div>
            <BuenaSenalForm/>
          </div>
        </div>
      </section>
      <section css={vigenteCss}>
        {/*<p>Vigente hasta el 20 de noviembre del 2021. <br/>Ver términos y condiciones <Link to="/terminosycondiciones/" css={css`color: #29A3DA;`}>aquí</Link></p>*/}
        <p>Ver términos y condiciones <Link to="/terminosycondiciones/" css={css`color: #29A3DA;`}>aquí</Link></p>
      </section>
    </LayoutGeneral>
  )
}
